import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { useEffect } from "react";
import { DateRange } from "react-day-picker";

export function useCheckRefetch(
  refetch: () => void,
  global: DateRange,
  ranges?: { [key: string]: DateRange },
  years?: { [key: string]: string },
  refetchTotals?: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<any, Error>>
) {
  useEffect(() => {
    if (
      (global && global.from && global.to) ||
      (ranges &&
        Object.values(ranges).some((range) => range?.from && range?.to)) ||
      (years && Object.keys(years).some((year) => year !== ""))
    ) {
      refetch();
      refetchTotals && refetchTotals();
    }
  }, [
    global,
    ranges && Object.keys(ranges).values(),
    years && Object.keys(years).values(),
  ]);
}
