import { z } from "zod";

export type Sku = {
  id?: string;
  company?: string;
  company_name?: string;
  brand?: string;
  brand_name?: string;
  sub_brand?: string;
  sub_brand_name?: string;
  sku?: string;
  measure?: string;
  measure_name?: string;
  type_measure?: string;
  type_measure_name?: string;
  waste_id?: string;
  waste_name?: string;
  waste_category_id?: string;
  waste_category_name?: string;
  product_wastes: product_wastes[];
  id_product_wastes?: string;
  image?: string;
  file?: File;
};

export type product_wastes = {
  waste_id: string;
  waste_category_id: string;
  waste_name?: string;
  waste_category_name?: string;
  image?: string;
  file?: File;
  id_product_wastes?: string;
  _destroy?: boolean;
};

export const ProductWasteSchema = z
  .object({
    waste_id: z
      .string({ required_error: "Residuo es requerido" })
      .refine((value) => value !== "default", {
        message: "Residuo es requerido",
      }),
    waste_category_id: z
      .string({ required_error: "Categoría es requerido" })
      .refine((value) => value !== "default", {
        message: "Categoría es requerido",
      }),
    waste_name: z.string().optional(),
    waste_category_name: z.string().optional(),
    image: z.string().optional(),
    file: z.any().optional(),
    id_product_wastes: z.string().optional(),
    _destroy: z.boolean(),
  })
  .refine(
    (data) => {
      if (data._destroy) {
        return true; // Si _destroy es true, no validamos waste_id ni waste_category_id
      }
      return !!data.waste_id && !!data.waste_category_id; // Si _destroy es false, ambos deben estar presentes
    },
    {
      message: "Categoría y Residuo son requeridos",
      path: ["waste_category_id"]
    }
  );

// Generated by https://quicktype.io

export interface SKUProductResponse {
  id: string;
  type: string;
  measure_unit: MeasureUnit;
  product_wastes: ProductWastes;
  waste_categories: WasteCategories;
  wastes: Wastes;
  company: Company;
  product_registrations: ProductRegistrations;
  recyclers: ProductRegistrations;
  sku: string;
  name: null;
  description: string;
  quantity: string;
  marca: string;
  submarca: string;
  packing_type: null;
  created_at: string;
  enterprise_name: string;
  image: string;
  badges_scanned: null;
  ecolana_scanned: null;
}

export interface Company {}

export interface MeasureUnitData {
  id: string;
  type: string;
}

export interface ProductRegistrations {
  data: MeasureUnitData[];
}

export interface ProductWastes {
  data: ProductWastesItemsSKU[];
}

export interface ProductWastesItemsSKU {
  id: string;
  type: string;
  waste: Waste;
  waste_category: WasteCategory;
  photo: string;
}

export interface Waste {
  data: WasteDatum;
}

export interface WasteDatum {
  id: string;
  type: string;
  name: string;
  description: string;
  active: boolean;
  platform_visible: string;
  icon: string;
  selected: boolean;
}

export interface WasteCategory {
  data: WasteCategoryDatum;
}

export interface WasteCategoryDatum {
  id: string;
  type: string;
  name: string;
  icon: null;
}

export interface WasteCategories {
  data: WasteCategoryDatum[];
}

export interface Wastes {
  data: WasteDatum[];
}

// Generated by https://quicktype.io

export interface MeasureUnits {
  measure_unit?: MeasureUnit;
}

export interface MeasureUnit {
  data?: DataMeasure;
}

export interface DataMeasure {
  id: string;
  type: string;
  name: string;
  symbol: string;
}
