import { useToast } from "@/components/atoms/shadcn/ui/use-toast";
import {
  CCInventory,
  CC_Form,
  CampaignSchemaCC,
  ResponseSettementData,
  ScheduleForm,
  Schedules,
  States,
  Towns,
  WastePerCategoryID,
  WasteSchemaCC,
  ZipCodes,
} from "@/interfaces";
import { useSession } from "next-auth/react";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { MapRef } from "react-map-gl";
import { useGeocode } from "./useGeocode";
import { CampaignsOptions } from "@/constants/campaignsData";
import { useQuery } from "@tanstack/react-query";
import { getActiveCampaigns } from "@/shared/functions/getActiveCampaigns";
import axios from "axios";
import { apiBaseURL, apiBaseURLV1 } from "@/constants";
import { dataServices } from "./dataServices";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { serialize } from "object-to-formdata";
import { useWastesData } from "./useWastesData";

const schemaCCForm = z.object({
  id: z.string().optional(),
  center_type: z
    .string({
      required_error: "Tipo de centro de acopio: Es un campo es requerido, ",
    })
    .optional(),
  collector: z.boolean({
    required_error: "Recollector: Es un campo es requerido, ",
  }),
  descripton: z.string().optional(),
  name: z
    .string({
      required_error: "Nombre: Es un campo es requerido, ",
    })
    .min(1, "Nombre: Es un campo es requerido, "),
  notes: z.string().optional(),
  schedule_id: z
    .string({
      required_error:
        "Días y Horarios de operación: Es un campo es requerido, ",
    })
    .optional(),
  ticket: z
    .string({
      required_error: "Valida: Es un campo es requerido, ",
    })
    .optional(),
  address: z.object({
    id: z.string().optional(),
    street: z
      .string({
        required_error: "Calle: Es un campo es requerido, ",
      })
      .min(1, "Calle: Es un campo es requerido, "),
    exterior: z
      .string({
        required_error: "Exterior: Es un campo es requerido, ",
      })
      .optional(),
    interior: z
      .string({
        required_error: "# Interior: Es un campo es requerido, ",
      })
      .optional(),
    lng: z.coerce.number({
      required_error: "Longitud: Es un campo es requerido, ",
    }),
    lat: z.coerce.number({
      required_error: "Latitud: Es un campo es requerido, ",
    }),
    state_id: z
      .string({
        required_error: "Estado: Es un campo es requerido, ",
      })
      .min(1, "Estado: Es un campo es requerido, "),
    town_id: z.string({
      required_error: "Municipio: Es un campo es requerido, ",
    }),
    zipcode_id: z
      .string({
        required_error: "Código Postal: Es un campo es requerido, ",
      })
      .optional(),
    settlement_id: z
      .string({
        required_error: "Colonia: Es un campo es requerido, ",
      })
      .optional(),
  }),
  socials: z.array(
    z.object({
      id: z.string().optional(),
      social_medium_attributes: z.object({
        id: z.string().optional(),
        site: z.string().optional(),
        url: z.string().optional(),
      }),
    })
  ),
  wastes: z.array(WasteSchemaCC),
  campaign: z.array(CampaignSchemaCC),
  benefits: z.string().optional(),
  benefits_id: z.string().optional(),
  contact: z.object({
    id: z.string().optional(),
    id_contact: z.string().optional(),
    center_type: z
      .string({
        required_error: "Tipo de centro de acopio: Es un campo es requerido, ",
      })
      .optional(),
    phone: z
      .string({
        required_error: "Teléfono: Es un campo es requerido, ",
      })
      .optional(),
    phone_ext: z
      .string({
        required_error: "Extensión de teléfono: Es un campo es requerido, ",
      })
      .optional(),
  }),
  image: z.string().optional(),
  file: z.any().optional(),
  qr_code: z.any().optional(),
});

const schemaCollector = z.object({
  id: z.string().optional(),
  collector: z.boolean({
    required_error: "Recollector: Es un campo es requerido, ",
  }),
  descripton: z.string().optional(),
  name: z
    .string({
      required_error: "Nombre: Es un campo es requerido, ",
    })
    .min(1, "Nombre: Es un campo es requerido, "),
  notes: z.string().optional(),
  schedule_id: z
    .string({
      required_error:
        "Días y Horarios de operación: Es un campo es requerido, ",
    })
    .optional(),
  address: z.object({
    id: z.string().optional(),
    state_id: z
      .string({
        required_error: "Estado: Es un campo es requerido, ",
      })
      .min(1, "Estado: Es un campo es requerido, "),
    lng: z.coerce.number({
      required_error: "Longitud: Es un campo es requerido, ",
    }),
    lat: z.coerce.number({
      required_error: "Latitud: Es un campo es requerido, ",
    }),
  }),
  wastes: z.array(WasteSchemaCC),
  campaign: z.array(CampaignSchemaCC),
  nup: z.object({
    id: z.string().optional(),
    nup_id: z.string().min(1),
    _destroy: z.boolean(),
  }),
  contact: z.object({
    id: z.string().optional(),
    id_contact: z.string().optional(),
    contact_type: z
      .string({
        required_error: "Tipo de contacto: Es un campo es requerido, ",
      })
      .optional(),
    phone: z
      .string({
        required_error: "Teléfono: Es un campo es requerido, ",
      })
      .optional(),
    phone_ext: z
      .string({
        required_error: "Extensión de teléfono: Es un campo es requerido, ",
      })
      .optional(),
  }),
  image: z.string().optional(),
  file: z.any().optional(),
  qr_code: z.any().optional(),
});

export const useCCForm = (isCC: boolean) => {
  const { data } = useSession();
  const { toast, dismiss } = useToast();
  const { fromAddress } = useGeocode();
  const [isOpenResponseNew, setIsOpenResponseNew] = useState(false);
  const mapRef = useRef<MapRef>(null);
  const {
    control: control_CC,
    watch: watch_CC,
    setValue: setValue_CC,
    getValues: getValue_CC,
    reset: reset_CC,
    handleSubmit: handleSubmit_CC,
    formState: { errors: errors_CC },
  } = useForm<CC_Form>({
    resolver: zodResolver(isCC ? schemaCollector : schemaCCForm),
    defaultValues: {
      id: undefined,
      center_type: undefined,
      collector: false,
      description: undefined,
      name: "",
      notes: "",
      schedule_id: undefined,
      ticket: undefined,
      address: {
        id: "",
        street: undefined,
        exterior: undefined,
        interior: undefined,
        lng: -99.13321738713675,
        lat: 19.432499068074158,
        state_id: "",
        town_id: "",
        zipcode_id: undefined,
        settlement_id: undefined,
      },
      socials: [],
      wastes: [],
      campaign: [],
      nup: { id: undefined, nup_id: undefined, _destroy: false },
      nup_id: undefined,
      benefits: undefined,
      contact: {
        id: undefined,
        contact_type: undefined,
        phone: "",
        phone_ext: "",
        email: "",
      },
      comments: [],
      stars: 0,
      active: false,
      image: undefined,
      file: undefined,
      qr_code: undefined,
    },
  });
  const {
    fields: fields_cc,
    append: append_cc,
    remove: remove_cc,
    update: update_cc,
  } = useFieldArray({
    name: "wastes",
    control: control_CC,
  });

  const {
    fields: fields_cc_campaign,
    append: append_cc_campaign,
    remove: remove_cc_campaign,
    update: update_cc_campaign,
  } = useFieldArray({
    name: "campaign",
    control: control_CC,
  });

  const {
    fields: fields_cc_socials,
    append: append_cc_socials,
    remove: remove_cc_socials,
    update: update_cc_socials,
  } = useFieldArray({
    name: "socials",
    control: control_CC,
  });
  const {
    fields: fields_comments,
    append: append_comments,
    remove: remove_comments,
    update: update_comments,
  } = useFieldArray({
    name: "comments",
    control: control_CC,
  });

  const {
    control: control_schedule,
    watch: watch_schedule,
    setValue: setValue_schedule,
    getValues: getValues_schedule,
    reset: reset_schedule,
    handleSubmit: handleSubmit_schedule,
  } = useForm<ScheduleForm>({
    defaultValues: {
      name: "",
      description: "",
      schedule_days: [],
    },
  });
  const {
    fields: fields_schedule,
    append: append_schedule,
    remove: remove_schedule,
    update: update_schedule,
  } = useFieldArray({
    name: "schedule_days",
    control: control_schedule,
  });

  const { data: totals, refetch: refetchTotals } = useQuery({
    queryKey: ["totals_ticket_grid"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      return data?.user?.token
        ? await dataServices({
            url: "/collection_centers/overview_totals",
            token: data?.user?.token || "",
          })
        : null;
    },
  });

  const { data: states } = useQuery<States[]>({
    queryKey: ["states"],
    queryFn: async () =>
      (
        await axios.get(`${apiBaseURLV1}/collection_centers/obtain_states`)
      ).data.data.flatMap((uniState: any) => {
        return { id: uniState.id, name: uniState.attributes.name };
      }),
  });
  const { wastes, wastes_categories } = useWastesData(data);

  const { data: wastes_perCategory } = useQuery<WastePerCategoryID[]>({
    queryKey: ["wastes_perCategory"],
    enabled: data?.user?.token && wastes_categories.length > 0 ? true : false,
    queryFn: async () => {
      const aux_all_wastes = wastes_categories.map(async (uni_category) => {
        const result = data?.user?.token
          ? await dataServices({
              url: "/wastes",
              params: {
                per_page: 100,
                waste_category_id: uni_category.id,
              },
              apiVersion: "v2",
              token: data?.user?.token ?? undefined,
            })
          : null;
        return {
          id: uni_category.id,
          name: uni_category.name,
          wastes: result.data.map((uniWaste: any) => ({
            id: uniWaste.id,
            name: uniWaste.name,
          })),
        };
      });
      const all_waste_categories: WastePerCategoryID[] = await Promise.all(
        aux_all_wastes
      );
      return all_waste_categories;
    },
  });

  const { data: campaigns } = useQuery<CampaignsOptions[]>({
    queryKey: ["campaigns"],
    queryFn: async () => await getActiveCampaigns(),
  });
  const { data: towns } = useQuery<Towns[]>({
    queryKey: [
      "towns",
      watch_CC("address.state_id"),
      watch_CC("address.settlement_id"),
    ],
    queryFn: async () =>
      (
        await axios.get(
          `${apiBaseURLV1}/collection_centers/obtain_towns?state_id=${watch_CC(
            "address.state_id"
          )}`
        )
      ).data.data.flatMap((uniTown: any) => {
        return { id: uniTown.id, name: uniTown.attributes.name };
      }),
    enabled: watch_CC("address.state_id") != "",
  });

  const { data: zipcodes } = useQuery<ZipCodes[]>({
    queryKey: [
      "zipcodes",
      watch_CC("address.town_id"),
      watch_CC("address.settlement_id"),
    ],
    queryFn: async () => {
      const result = data?.user?.token
        ? await dataServices({
            url: "/collection_centers/obtain_zip_codes",
            apiVersion: "v1",
            params: {
              town_id: watch_CC("address.town_id"),
            },
            token: data?.user?.token ?? undefined,
          })
        : null;
      return result.data.map((uniTown: any) => ({
        id: uniTown.id,
        name: uniTown.code,
      }));
    },
    enabled: watch_CC("address.town_id") != "",
  });

  const { data: settlement } = useQuery<ZipCodes[]>({
    queryKey: ["settlement", watch_CC("address.zipcode_id")],
    queryFn: async () => {
      const result = data?.user?.token
        ? await dataServices({
            url: "/addresses/obtain_settlement",
            apiVersion: "v1",
            deserialize: true,
            params: {
              zipcode_id: watch_CC("address.zipcode_id"),
            },
            token: data?.user?.token ?? undefined,
          })
        : null;
      let keysSettlements = Object.keys(result);
      keysSettlements.pop();
      const dataReturn = keysSettlements.map((uni_key) => {
        return {
          id: String(result[uni_key].id),
          name: String(result[uni_key].name),
        };
      });
      return dataReturn;
    },
    enabled: watch_CC("address.zipcode_id") != "",
  });

  const { data: nups } = useQuery<ZipCodes[]>({
    queryKey: ["nup"],
    queryFn: async () => {
      const result = data?.user?.token
        ? await dataServices({
            url: "/nups",
            token: data?.user?.token ?? undefined,
          })
        : null;
      return result.data.map((uniTown: any) => ({
        id: uniTown.id,
        name: uniTown.name,
      }));
    },
  });

  const { data: schedules, refetch: refetch_schedules } = useQuery<Schedules[]>(
    {
      queryKey: ["schedules"],
      queryFn: async () => {
        const result = data?.user?.token
          ? await dataServices({
              url: "/schedules",
              token: data?.user?.token ?? undefined,
            })
          : null;
        return result.data.map((uniTown: any) => ({
          slug: uniTown.id,
          text_label: uniTown.name,
        }));
      },
    }
  );

  function handleCloseResponseNew() {
    setIsOpenResponseNew(!isOpenResponseNew);
  }

  const getMapPosition = async () => {
    if (
      mapRef.current &&
      (watch_CC("address.state_id") != "" || watch_CC("address.town_id") != "")
    ) {
      const name_state = states?.find(
        (uni_state) => uni_state.id == watch_CC("address.state_id")
      )?.name;
      const name_town = towns?.find(
        (uni_town) => uni_town.id == watch_CC("address.town_id")
      )?.name;
      const address_text = name_town
        ? `${name_town}, ${name_state}, México`
        : `${name_state}, México`;
      const response = await fromAddress(address_text);
      const bounds =
        response.results[response.results.length - 1].geometry.bounds;
      mapRef.current?.fitBounds([
        { lat: bounds.southwest.lat, lng: bounds.southwest.lng },
        { lat: bounds.northeast.lat, lng: bounds.northeast.lng },
      ]);
      setValue_CC(
        "address.lat",
        response.results[response.results.length - 1].geometry.location.lat
      );
      setValue_CC(
        "address.lng",
        response.results[response.results.length - 1].geometry.location.lng
      );
    }
  };

  useEffect(() => {
    getMapPosition();
  }, [watch_CC("address.town_id"), watch_CC("address.state_id")]);

  async function resetData(dataReset: CCInventory) {
    reset_CC();
    setValue_CC("center_type", dataReset.type_cc_id);
    setValue_CC("collector", dataReset.isCollector);
    setValue_CC("id", dataReset.id);
    setValue_CC("name", dataReset.name);
    setValue_CC("ticket", dataReset.ticket);
    setValue_CC("active", dataReset.active);
    setValue_CC("address.exterior", dataReset?.address?.exterior);
    setValue_CC("address.id", dataReset?.address?.id);
    setValue_CC("address.interior", dataReset?.address?.interior);
    setValue_CC("address.lat", dataReset?.address?.lat);
    setValue_CC("address.lng", dataReset?.address?.lng);
    setValue_CC("address.settlement_id", dataReset?.address?.settlement_id);
    setValue_CC("address.street", dataReset?.address?.street);
    setValue_CC("benefits", dataReset.benefit?.name);
    setValue_CC("benefits_id", dataReset.benefit?.id);
    setValue_CC("schedule_id", dataReset?.schedule_id);
    setValue_CC("contact.id", dataReset.contact?.id ?? undefined);
    setValue_CC(
      "contact.id_contact",
      dataReset.contact?.id_contact ?? undefined
    );
    setValue_CC("notes", dataReset.notes);
    setValue_CC("contact.phone", dataReset.contact?.phone ?? undefined);
    setValue_CC("contact.phone_ext", dataReset.contact?.phone_ext ?? undefined);
    setValue_CC("contact.contact_type", dataReset.contact?.contact_type ?? "1");
    dataReset.campaigns.map((item, idx) => {
      append_cc_campaign({
        id: item.id,
        campaign_id: item.campaign_id,
        name: item.name,
        _destroy: false,
      });
    });
    dataReset.wastes.map((item, idx) => {
      append_cc({
        id: item.id_waste_valdation,
        waste_id: item.waste_id,
        _destroy: false,
      });
    });
    ["facebook", "instagram", "tiktok", "twitter"].forEach((uni_red_social) => {
      const red_social = dataReset.socials.find(
        (uni_red) => uni_red.social_medium_attributes.site == uni_red_social
      );
      if (red_social) {
        append_cc_socials({
          id: red_social.id,
          social_medium_attributes: {
            id: red_social.social_medium_attributes.id,
            site: uni_red_social,
            url: red_social.social_medium_attributes.url,
          },
        });
      } else {
        append_cc_socials({
          id: undefined,
          social_medium_attributes: {
            id: undefined,
            site: uni_red_social,
            url: undefined,
          },
        });
      }
    });
    if (dataReset.isCollector) {
      setValue_CC("nup.nup_id", dataReset.nup.nup_id);
      setValue_CC("nup.id", dataReset.nup.id);
    }
    if (!dataReset.isCollector) {
      const resultsAddress: ResponseSettementData = await dataServices({
        url: `/addresses/obtain_data_per_settlement?settlement_id=${dataReset?.address?.settlement_id}`,
        apiVersion: "v1",
        token: data?.user?.token ?? undefined,
      });

      setValue_CC(
        "address.state_id",
        resultsAddress.states[0].id
          ? String(resultsAddress.states[0].id)
          : undefined
      );
      setValue_CC(
        "address.town_id",
        resultsAddress.towns[0].id
          ? String(resultsAddress.towns[0].id)
          : undefined
      );
      setValue_CC(
        "address.zipcode_id",
        resultsAddress.zip_codes.id
          ? String(resultsAddress.zip_codes.id)
          : undefined
      );
    }
  }

  async function resetComments(dataReset: CCInventory) {
    reset_CC();
    dataReset.comments.map((item_comment) => {
      append_comments({
        id: item_comment.id,
        created_at: item_comment.created_at,
        description: item_comment.description,
        score: item_comment.score,
      });
    });
  }

  function handleCampaignChange(id_campaign: string) {
    const existingCampaign = watch_CC("campaign")?.findIndex(
      (element) => element.campaign_id == id_campaign
    );
    if (existingCampaign != undefined) {
      if (existingCampaign > -1) {
        const dataCampaign = watch_CC(`campaign.${existingCampaign}`);
        remove_cc_campaign(existingCampaign);
        append_cc_campaign({
          ...dataCampaign,
          _destroy: !dataCampaign._destroy,
        });
      } else if (existingCampaign < 0) {
        append_cc_campaign({ campaign_id: id_campaign, _destroy: false });
      }
    }
  }

  useEffect(() => {
    mapRef.current?.setCenter({
      lat: watch_CC("address.lat") || 24.13686865831984,
      lng: watch_CC("address.lng") || -102.4256922299597,
    });
  }, [watch_CC("address.lat"), watch_CC("address.lng")]);

  async function onSubmitNewSchedule() {
    try {
      const dataNewSchedule = getValues_schedule();
      const object_to_update = {
        schedule: {
          ...dataNewSchedule,
          schedule_days_attributes: dataNewSchedule.schedule_days,
        },
      };
      reset_schedule();
      const formData = serialize(object_to_update);
      const response = await dataServices({
        url: `/schedules`,
        requestType: "POST",
        apiVersion: "v2",
        body: formData,
        contentType: "multipart/form-data",
        token: data?.user?.token ?? undefined,
      });
      toast({
        title: "Formato de horario: Se ha creado con éxito",
        description: `Se ha creado el formato de horario con éxito.`,
      });
      refetch_schedules();
    } catch (error) {
      console.log(error);
      toast({
        title: " Error: Formato de horario",
        description: `Error al crear el formato de horario.`,
      });
    }
  }

  async function onSubmitNewCC(dataCC: CC_Form) {
    const object_to_update = {
      collection_center: {
        active: true,
        center_type: dataCC.center_type,
        collector: dataCC.collector,
        description: dataCC.description,
        name: dataCC.name,
        notes: dataCC.notes,
        ticket: dataCC.ticket,
        schedule_id: dataCC?.schedule_id,
        address_attributes: {
          id: dataCC?.address?.id,
          street: dataCC?.address?.street,
          exterior: dataCC?.address?.exterior,
          interior: dataCC?.address?.interior,
          lonlat: `POINT(${dataCC?.address?.lng} ${dataCC?.address?.lat})`,
          settlement_id: dataCC.address.settlement_id,
          state_id: dataCC.address.state_id,
        },
        collection_center_wastes_attributes: dataCC.wastes?.map(
          (uni_waste) => ({
            id: uni_waste.id,
            waste_id: uni_waste.waste_id,
            _destroy: uni_waste._destroy,
          })
        ),
        campaign_collection_centers_attributes: dataCC.campaign?.map(
          (uni_campaign) => ({
            id: uni_campaign.id,
            campaign_id: uni_campaign.campaign_id,
            _destroy: uni_campaign._destroy,
          })
        ),
        collection_center_socials_attributes: dataCC.socials,
        collection_center_benefit_attributes: {
          id: dataCC.benefits_id,
          name: dataCC.benefits,
        },
        contact_attributes: {
          id: dataCC.contact.id_contact,
          phone: dataCC.contact.phone,
          phone_ext: dataCC.contact.phone_ext,
        },
        collection_center_nup_attributes:
          dataCC.nup?.nup_id != undefined
            ? {
                id: undefined,
                nup_id: dataCC.nup?.nup_id,
              }
            : undefined,
        image: dataCC.file,
      },
    };
    const formData = serialize(object_to_update);
    const response = await dataServices({
      url: `/collection_centers`,
      requestType: "POST",
      apiVersion: "v2",
      body: formData,
      contentType: "multipart/form-data",
      token: data?.user?.token ?? undefined,
    });
    reset_form();
    setIsOpenResponseNew(true);
    if (dataCC.collector) {
      setValue_CC(
        "qr_code",
        response?.data?.data?.attributes?.qr_code ?? undefined
      );
    }
  }

  function reset_form() {
    mapRef.current?.setCenter({
      lat: 24.13686865831984,
      lng: -102.4256922299597,
    });
    refetchTotals();
    mapRef.current?.setZoom(4);
    reset_CC();
    reset_socials();
  }

  function reset_socials() {
    const socials = [
      {
        id: undefined,
        social_medium_attributes: {
          id: undefined,
          site: "facebook",
          url: undefined,
        },
      },
      {
        id: undefined,
        social_medium_attributes: {
          id: undefined,
          site: "instagram",
          url: undefined,
        },
      },
      {
        id: undefined,
        social_medium_attributes: {
          id: undefined,
          site: "tiktok",
          url: undefined,
        },
      },
      {
        id: undefined,
        social_medium_attributes: {
          id: undefined,
          site: "twitter",
          url: undefined,
        },
      },
    ];
    remove_cc_socials();
    if (!watch_CC("collector")) {
      socials.forEach((uni_social) => {
        append_cc_socials(uni_social);
      });
    }
  }

  useEffect(() => {
    if (
      errors_CC.address?.street ||
      errors_CC.address?.state_id ||
      errors_CC.schedule_id ||
      errors_CC.name
    ) {
      toast({
        title: "Llene el formulario correctamente",
        description: `${errors_CC?.name ? errors_CC?.name.message : ""} ${
          errors_CC.address?.street ? errors_CC.address?.street.message : ""
        } \n ${
          errors_CC.address?.state_id ? errors_CC.address?.state_id.message : ""
        } ${errors_CC?.schedule_id ? errors_CC?.schedule_id.message : ""} `,
        variant: "warning",
      });
    }
  }, [errors_CC]);

  useEffect(() => {
    setValue_CC("collector", isCC);
  }, [isCC]);

  return {
    control_CC,
    reset_CC,
    handleSubmit_CC,
    watch_CC,
    setValue_CC,
    getValue_CC,
    fields_cc,
    fields_cc_campaign,
    append_cc,
    remove_cc,
    update_cc,
    wastes: wastes || [],
    wastes_categories: wastes_categories || [],
    wastes_perCategory: wastes_perCategory || [],
    states: states || [],
    towns: towns || [],
    campaigns: campaigns || [],
    zipcodes: zipcodes || [],
    nups: nups || [],
    settlement: settlement || [],
    schedules: schedules || [],
    mapRef,
    control_schedule,
    watch_schedule,
    setValue_schedule,
    fields_schedule,
    append_schedule,
    remove_schedule,
    update_schedule,
    getValues_schedule,
    handleSubmit_schedule,
    resetData,
    resetComments,
    handleCampaignChange,
    onSubmitNewSchedule,
    onSubmitNewCC,
    isOpenResponseNew,
    handleCloseResponseNew,
    totals,
    fields_cc_socials,
    append_cc_socials,
    reset_form,
  };
};
