import { useSession } from "next-auth/react";
import { usePagination } from "./usePagination";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { dataServices } from "./dataServices";
import { useForm } from "react-hook-form";
import { DateRange } from "react-day-picker";

export const useDataScan = (per_page: number) => {
  const { data } = useSession();
  const { control, watch } = useForm<{
    globalRange: DateRange;
    search: string;
  }>({
    defaultValues: {
      globalRange: {
        from: undefined,
        to: undefined,
      },
      search: "",
    },
  });
  const {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    last_page,
    paginationLabel,
    setTotal,
    setTotalPages,
    setCurrentPage,
    pageSize,
    onChangePageSize,
  } = usePagination(per_page);
  const query = useQuery({
    queryKey: ["scans", currentPage, pageSize],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const result = data?.user?.token
        ? await dataServices({
            url: "/product_scanneds",
            params: {
              page: currentPage,
              per_page: pageSize,
              admin: true,
              include_product: "true",
              "include_recycler.user": "true",
              include_badge: "true",
              start_date:
                watch("globalRange.from") !== undefined
                  ? watch("globalRange.from")?.toJSON()
                  : "",
              end_date:
                watch("globalRange.to") !== undefined
                  ? watch("globalRange.to")?.toJSON()
                  : "",
              search: watch("search") || "",
            },
            token: data?.user?.token || "",
          })
        : null;
      const dataFormatted = result.data.map((item: any) => {
        return {
          id: item.id,
          user_name:
            item.recycler.data.first_name !== "" &&
            item.recycler.data.first_name !== null
              ? item.recycler.data.first_name
              : "# " + item.recycler.data.id,
          date_insert: new Date(item.created_at).toLocaleDateString("es-MX"),
          email: item.recycler.data.user.data.email,
          sku: item.product.data.sku,
          badge: item.badge.data ? item.badge.data.name : "",
        };
      });
      return {
        meta: result.meta,
        data: dataFormatted,
      };
    },
  });
  useEffect(() => {
    if (query.data) {
      setTotalPages(query.data.meta.total_pages);
      setTotal(query.data.meta.total_count);
    }
  }, [query]);

  const { data: totals, refetch: refetchTotals } = useQuery({
    queryKey: ["total_scans", pageSize],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      return data?.user?.token
        ? await dataServices({
            url: "/product_scanneds/overview_totals",
            token: data?.user?.token || "",
          })
        : null;
    },
  });

  useEffect(() => {
    setCurrentPage(1);
    query.refetch();
    refetchTotals();
  }, [
    watch("globalRange.from"),
    watch("globalRange.to"),
    watch("search"),
    pageSize,
  ]);

  return {
    query,
    control_date: control,
    totals: totals,
    pagination: {
      currentPage,
      firstPage,
      lastPage,
      nextPage,
      prevPage,
      paginationLabel,
      last_page,
      onChangePageSize,
      pageSize,
    },
  };
};
