import * as React from "react";

import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";

//  dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 ring-offset-white

export const inputVariants = cva("flex h-10 w-full px-3 text-sm rounded-md", {
  variants: {
    variant: {
      default:
        "border-slate-200 bg-white text-sm rounded-md border-2  file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-white placeholder:font-bold  disabled:cursor-not-allowed disabled:opacity-50 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-transparent focus-visible:ring-offset-0",
      adminGray:
        "focus-visible:outline-none ring-offset-transparent bg-admins-input-disabled_bg placeholder:text-admins-text-inactive text-admins-text-inactive disabled:cursor-not-allowed disabled:opacity-50",
      adminWhite:
        "focus-visible:outline-none ring-offset-transparent bg-white placeholder:text-admins-text-inactive text-admins-text-inactive disabled:cursor-not-allowed disabled:opacity-50",
      adminWhiteBorder:
        "focus-visible:outline-none ring-offset-transparent border border-admins-text-active bg-white placeholder:text-admins-text-inactive text-admins-text-inactive disabled:cursor-not-allowed disabled:opacity-50",
      adminGhost:
        "focus-visible:outline-none ring-offset-transparent bg-transparent placeholder:text-admins-text-inactive text-admins-text-inactive disabled:cursor-not-allowed disabled:opacity-50",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, variant, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Input.displayName = "Input";

export { Input };
